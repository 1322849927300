import { type ColorTheme } from "..";
export const getDarkBlueColorTheme = (): ColorTheme => ({
	name: "Dark Blue",
	ribbon: {
		primaryBg: "var(--chakra-colors-AWPrimary-darkBlue)",
		primaryColor: "var(--chakra-colors-AWFunctional-white)",
		primaryColorBigText: "var(--chakra-colors-AWFunctional-white)",
		quoteSecondaryColor: "var(--chakra-colors-AWPrimary-darkBlue)",
		secondaryBg: "var(--chakra-colors-AWPrimary-lightBlue)",
		secondaryColor: "var(--chakra-colors-AWPrimary-lightBlue)",
		midColor: "var(--chakra-colors-AWPrimary-midBlue)",
		primaryButtonBg: "var(--chakra-colors-AWPrimary-midBlue)",
		primaryButtonColor: "var(--chakra-colors-AWPrimary-dark)",
		primaryButtonHoverBg: "#00B4D4",
		primaryBorderColor: "var(--chakra-colors-AWPrimary-midBlue)",
		tagDefaultDarkBgColor: "rgba(var(--chakra-colors-AWFunctionalHex-white), 0.18)",
		tagDefaultDarkBorderColor: "rgba(var(--chakra-colors-AWFunctionalHex-white), 0.2)",
		tagDefaultBgColor: "rgba(var(--chakra-colors-AWFunctionalHex-grey), 0.08)",
		tagDefaultBorderColor: "rgba(var(--chakra-colors-AWFunctionalHex-grey), 0.2)",
		progressBarBg: "var(--chakra-colors-AWPrimary-darkBlue)",
	},
});