/**
 * Used in backend to whitelist sizes
 * When sizes are changed or added, run `npm run build`
 */

/**
 * Values can be:
 * w (width)
 * h (height)
 * mw (max width)
 * mh (max height)
 * cw (crop width)
 * ch (crop height)
 */
export const imageSizes = {
	none: {
		mw: 10,
	},
	default: { //16:9
		sm: {
			cw: 384,
			ch: 216,
		},
		md: {
			cw: 768,
			ch: 432,
		},
		lg: {
			cw: 1152,
			ch: 648,
		},
		xl: {
			cw: 1440,
			ch: 810,
		}
	},
	wide: { //3 : 1 - fx. StoryPageHeader
		sm: {
			cw: 480,
			ch: 160,
		},
		md: {
			cw: 768,
			ch: 256,
		},
		lg: {
			cw: 1281,
			ch: 427,
		},
		xl: {
			cw: 1464,
			ch: 488,
		}
	},
	contentSection: {
		sm: {
			cw: 384,
			ch: 216,
		},
		md: {
			cw: 866,
			ch: 488,
		}
	},
	fitContentWrapper: {
		sm: {
			w: 390,
		},
		md: {
			w: 866,
		},
		lg: {
			w: 1184,
		},
		xl: {
			w: 1464,
		}
	},
	articleList: { // 4 : 3
		sm: {
			cw: 480,
			ch: 360,
		},
		md: {
			cw: 768,
			ch: 576,
		},
	},
	highlightedContent: {
		narrow: {
			sm: {
				w: 171
			},
			lg: {
				w: 268
			}
		},
		wide: {
			sm: {
				w: 480
			},
			md: {
				w: 768
			},
			lg: {
				w: 566
			}
		}
	},
	recipe: {
		sm: {
			cw: 480,
			ch: 509,
		},
		lg: {
			cw: 764,
			ch: 810,
		}
	},
	review: {
		sm: {
			h: 312
		},
		md: {
			h: 512
		}
	},
	contributer: {
		sm: {
			cw: 178,
			ch: 200,
		},
	},
	square: {
		xs: {
			cw: 140,
			ch: 140
		},
		sm: {
			cw: 265,
			ch: 265
		}
	},
	articleAuthor: {
		sm: {
			cw: 186,
			ch: 186
		},
	},
	videoList: {
		sm: {
			cw: 169,
			ch: 107
		},
		md: {
			cw: 417,
			ch: 261

		}
	}
}