import { Text as ThemeTextStyling } from "../../Theme/components/Text";

export const RichTextStyling = () => ({
	sx: {
		...RichTextStylingBase()
	}
});

//Base richtext styling are also used as base styling on migrated articles in ArticleTextMigrated
export const RichTextStylingBase = () => ({
	...ThemeTextStyling().sizes?.p18,

	'p, li, td': {
		...ThemeTextStyling().sizes?.p18,
		lineHeight: "1.5",
		mb: '1.775rem',
		'&:last-child': {
			mb: '0'
		},
		"+ h1, + h2": {
			mt: ["8", null, null, "12"],
		}
	},
	"h1, h2": {
		...ThemeTextStyling().sizes?.desktopHeadline5,
		mb: ["4", null, null, "6"],
	},
	"h3, h4, h5, h6": {
		...ThemeTextStyling().sizes?.desktopHeadline6,
		mb: ["1", null, null, "2"],
	},
	'ul, ol': {
		ps: ['1.65rem', null, null, '1.75rem'],
		mb: '1.75rem',
		'&:last-child': {
			mb: '0'
		},

		"li": {
			mb: "2"
		},
		"ul, ol": {
			mt: "2"
		},
		"+ h1, + h2": {
			mt: ["8", null, null, "12"],
		}
	},
	'a': {
		position: "relative",
		display: "inline",
		textDecoration: "underline",
		cursor: "pointer",
		"+ h1, + h2": {
			mt: ["8", null, null, "12"],
		},
	},
	"table": {
		display: "block",
		color: "AWPrimary.dark",
		background: "white",
		borderCollapse: "collapse",
		overflow: "auto",
		maxW: "100%",
		mb: '1.75rem',
		"caption": {
			padding: ".5em"
		},
		"td, th": {
			padding: ".5em",
			borderStyle: "solid",
			borderWidth: "1px",
			borderColor: "AWFunctional.midGray",
		},
		"th": {
			backgroundColor: "AWFunctional.lightGray",
		}
	}
});