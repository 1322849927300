import { type ComponentStyleConfig } from "@chakra-ui/react";
import { Text as ThemeTextStyling } from "./Text";

export const getLinkSizes = () => ({
	//OBS this css chunk is imported in none chakra components such as NavLink, so do not use chakra theme variables with shortname
	sm: {
		...ThemeTextStyling().variants?.ctaSmall,
		padding: ".5rem 1.25rem",
	},
	lg: {
		...ThemeTextStyling().variants?.cta,
		padding: ".75rem 2.125rem",
	},
});

export const getLinkVariants = () => ({
	primaryDark: {
		backgroundColor: "AWPrimary.dark",
		textColor: "AWFunctional.white",
		fontWeight: "light",
		py: 3,
		px: 6,
		borderRadius: "full",
		"&:hover, &:active": {
			backgroundColor: "AWPrimary.softDark",
		},
	},
	defaultBlue: {
		backgroundColor: "AWPrimary.midBlue",
		textColor: "AWPrimary.darkBlue",
		borderRadius: "full",
		"&:hover, &:active": {
			backgroundColor: "#00B4D4"
		}
	},
	//OBS this css chunk is imported in none chakra components such as NavLink, so do not use chakra theme variables with shortname
	primaryOutline: {
		whiteSpace: "nowrap",
		backgroundColor: "transparent",
		textColor: "var(--chakra-colors-AWPrimary-dark)",
		border: "1px solid",
		borderColor: "var(--chakra-colors-AWPrimary-dark)",
		py: 3,
		px: 6,
		fontWeight: "600",
		alignItems: "center",
		borderRadius: "var(--chakra-radii-full)",
		transition: "color, background .3s",
		_disabled: {
			backgroundColor: "var(--chakra-colors-AWPrimary-dark)",
			color: "var(--chakra-colors-AWFunctional-white)",
			"& svg": {
				color: "var(--chakra-colors-AWFunctional-white)",
				strokeColor: "var(--chakra-colors-AWFunctional-white)",
			}
		},
		"&:hover, &:active": {
			backgroundColor: "var(--chakra-colors-AWPrimary-dark)",
			color: "var(--chakra-colors-AWFunctional-white)",
			"& svg": {
				color: "var(--chakra-colors-AWFunctional-white)",
				strokeColor: "var(--chakra-colors-AWFunctional-white)",
			}
		},
		_focusVisible: {
			outline: "1px solid",
			outlineColor: "var(--chakra-colors-AWPrimary-dark)"
		},
	},
	primarySmallOutline: {
		backgroundColor: "transparent",
		textColor: "var(--chakra-colors-AWPrimary-dark)",
		fontWeight: "light",
		border: "1px solid",
		borderColor: "var(--chakra-colors-AWPrimary-dark)",
		height: "fit-content",
		width: "fit-content",
		padding: "var(--chakra-sizes-2) var(--chakra-sizes-6)",
		alignItems: "center",
		borderRadius: "3.625rem",
		transition: "color, background .3s",
		"&:hover, &:active": {
			backgroundColor: "var(--chakra-colors-AWPrimary-dark)",
			color: "white",
		},
	},
	tag: {
		padding: "0.325rem 0.5rem",
		border: "1px solid",
		borderRadius: "3.625rem",
		fontSize: "0.75rem",
		backgroundColor: "#ededed"
	},
	unstyled: {
		fontFamily: "inherit",
		fontWeight: "inherit",
		transition: "none",
		"&:hover, &:active": {
			textDecoration: "none",
		},
	},
	ctaBlue: {
		fontFamily: 'body',
		fontSize: ".9375rem",
		fontWeight: "600",
		lineHeight: "normal",
		letterSpacing: "-0.02em",
		backgroundColor: "AWPrimary.midBlue",
		color: "AWPrimary.darkBlue",
		px: 6,
		py: 3,
		borderRadius: "full",
		transition: "background-color .3s cubic-bezier(.35,1,.45,1)",
		"&:hover, &:active": {
			backgroundColor: "AWPrimary.lightBlue",
		},
	},
	outlineDark: {
		py: 3,
		px: 6,
		borderRadius: "full",
		fontFamily: 'body',
		fontSize: ".875rem",
		fontWeight: "600",
		lineHeight: "normal",
		border: "1px solid",
		borderColor: "AWPrimary.dark",
		textColor: "AWPrimary.dark",
		backgroundColor: "AWFunctional.white",
		transition: "all .3s cubic-bezier(.35,1,.45,1)",
		"&:hover, &:active": {
			backgroundColor: "AWPrimary.dark",
			textColor: "AWFunctional.white",
		},
	},
	primaryBlue: {
		backgroundColor: "AWPrimary.midBlue",
		textColor: "AWPrimary.dark",
		fontWeight: 600,
		py: 2,
		px: 6,
		borderRadius: "full",
		"&:hover, &:active": {
			backgroundColor: "AWPrimary.darkerBlue",
		},
		_focusVisible: {
			outline: "1px solid",
			outlineCoor: "AWPrimary.dark"
		},
	},
	filterTag: {
		fontFamily: 'body',
		fontSize: ".75rem",
		fontWeight: "600",
		lineHeight: "1.2",
		letterSpacing: "0",
		display: "inline-flex",
		alignItems: "center",
		bg: "rgba(21, 29, 43, 0.08)",
		borderRadius: "1.25rem",
		_focusVisible: {
			outline: "1px solid",
			outlineColor: "AWPrimary.dark"
		},
		padding: "0.375rem 0.5rem",
		justifyContent: "center",
		gap: "0.25rem",
		flexWrap: "wrap",
		_hover: {
			bg: "rgba(21, 29, 43, 0.24)"
		}
	}

});

export const LinkBaseStyle = {
	baseStyle: {
		height: "fit-content",
		width: "fit-content",
		alignItems: "center",
		transition: "all ease 0.3s",
		"&[aria-disabled=true]": {
			opacity: .6,
			cursor: "not-allowed"
		},
		_focusVisible: {
			outline: "1.5px solid",
			outlineColor: "var(--chakra-colors-AWPrimary-dark)"
		},
	},
}

export type LinkSize = keyof ReturnType<typeof getLinkSizes>;
export type LinkVariant = keyof ReturnType<typeof getLinkVariants>;

export const Link = (): ComponentStyleConfig => ({
	...LinkBaseStyle,
	sizes: getLinkSizes(),
	variants: getLinkVariants(),
	defaultProps: {
		_firstLetter: {
			textTransform: "uppercase",
		}
	},
});
