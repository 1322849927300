import { type ColorTheme } from "..";

export const getMidGreenColorTheme = (): ColorTheme => ({
	name: "Mid Green",
	ribbon: {
		primaryBg: "var(--chakra-colors-AWPrimary-midGreen)",
		primaryColor: "var(--chakra-colors-AWPrimary-dark)",
		primaryColorBigText: "var(--chakra-colors-AWPrimary-darkGreen)",
		quoteSecondaryColor: "var(--chakra-colors-AWPrimary-darkGreen)",
		secondaryBg: "var(--chakra-colors-AWPrimary-lightGreen)",
		secondaryColor: "var(--chakra-colors-AWPrimary-dark)",
		midColor: "var(--chakra-colors-AWPrimary-dark)",
		primaryButtonBg: "var(--chakra-colors-AWPrimary-dark)",
		primaryButtonColor: "var(--chakra-colors-AWFunctional-white)",
		primaryButtonHoverBg: "var(--chakra-colors-AWPrimary-softDark)",
		primaryBorderColor: "var(--chakra-colors-AWPrimary-dark)",
		tagDefaultDarkBgColor: "rgba(var(--chakra-colors-AWFunctionalHex-grey), 0.08)",
		tagDefaultDarkBorderColor: "rgba(var(--chakra-colors-AWFunctionalHex-grey), 0.2)",
		tagDefaultBgColor: "rgba(var(--chakra-colors-AWFunctionalHex-grey), 0.08)",
		tagDefaultBorderColor: "rgba(var(--chakra-colors-AWFunctionalHex-grey), 0.2)",
		progressBarBg: "var(--chakra-colors-AWPrimary-midGreen)",
	},
});