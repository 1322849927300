const AWPrimary = {
	dark: "#151D2B",
	midDark: "#202630",
	softDark: "#3F4A65",
	darkRed: "#4A2020",
	red: "#A01D00",
	midRed: "#FC6D4E",
	lightRed: "#FFDEDA",
	darkOrange: "#472C20",
	midOrange: "#FF9A3E",
	lightOrange: "#FBEDBC",
	darkYellow: "#484900",
	midYellow: "#F9E423",
	lightYellow: "#FFF8B7",
	darkGreen: "#023730",
	midGreen: "#CDEB69",
	lightGreen: "#D0FFDD",
	darkBlue: "#1B3D56",
	darkerBlue: "#00B4D4",
	midBlue: "#6FE7FE",
	lightBlue: "#CAF7FF",
	darkPurple: "#451E77",
	midPurple: "#C680FF",
	lightPurple: "#EBD2FF",
};

const AWFunctional = {
	black: "#000",
	darkGray: "#9CA0A5",
	midGray: "#C7C9CC",
	softGray: "#ECEDEE",
	lightGray: "#E9E9E9",
	lighterGray: "#F0F0F0",
	white: "#FFF",
};
const AWFunctionalHex = {
	white: "255,255,255",
	grey: "21, 29, 43",
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const colors = {
	AWPrimary,
	AWFunctional,
	AWFunctionalHex
};