import { type ColorTheme } from "..";

export const getDarkGreenColorTheme = (): ColorTheme => ({
	name: "Dark Green",
	ribbon: {
		primaryBg: "var(--chakra-colors-AWPrimary-darkGreen)",
		primaryColor: "var(--chakra-colors-AWFunctional-white)",
		primaryColorBigText: "var(--chakra-colors-AWFunctional-white)",
		quoteSecondaryColor: "var(--chakra-colors-AWPrimary-darkGreen)",
		secondaryBg: "var(--chakra-colors-AWPrimary-lightGreen)",
		secondaryColor: "var(--chakra-colors-AWPrimary-lightGreen)",
		midColor: "var(--chakra-colors-AWPrimary-midGreen)",
		primaryButtonBg: "var(--chakra-colors-AWPrimary-midGreen)",
		primaryButtonColor: "var(--chakra-colors-AWPrimary-dark)",
		primaryButtonHoverBg: "#AECA52",
		primaryBorderColor: "var(--chakra-colors-AWPrimary-midGreen)",
		tagDefaultDarkBgColor: "rgba(var(--chakra-colors-AWFunctionalHex-white), 0.18)",
		tagDefaultDarkBorderColor: "rgba(var(--chakra-colors-AWFunctionalHex-white), 0.2)",
		tagDefaultBgColor: "rgba(var(--chakra-colors-AWFunctionalHex-grey), 0.08)",
		tagDefaultBorderColor: "rgba(var(--chakra-colors-AWFunctionalHex-grey), 0.2)",
		progressBarBg: "var(--chakra-colors-AWPrimary-darkGreen)",
	},
});