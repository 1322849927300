import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { inputAnatomy } from '@chakra-ui/anatomy'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = definePartsStyle({
	field: {
		_placeholder: {
			color: "primary.greyDark"
		},
	},
});

const searchModalDesktop = definePartsStyle({
	field: {
		color: "AWFunctional.white",
		fontFamily: "body",
		fontSize: "3.5rem",
		fontWeight: "600",
		lineHeight: "1.2",
		letterSpacing: "-0.03",
		borderRadius: 0,
		bg: "AWPrimary.dark",
		height: "full",
		_placeholder: {
			color: "AWFunctional.white",
			opacity: 0.2,
		},
		_autofill: {
			border: "1px solid transparent",
			textFillColor: "#c6c6c6",
			boxShadow: "0 0 0px 1000px var(--chakra-colors-AWPrimary-dark) inset",
			transition: "background-color 5000s ease-in-out 0s",
		},
		px: 0,
	},
});

const searchModalMobile = definePartsStyle({
	field: {
		color: "AWFunctional.white",
		fontFamily: "body",
		fontSize: "2rem",
		fontWeight: "600",
		lineHeight: "1.2",
		letterSpacing: "-0.06",
		borderRadius: 0,
		bg: "AWPrimary.dark",
		height: "full",
		_placeholder: {
			color: "AWFunctional.white",
			opacity: 0.2,
		},
		_autofill: {
			border: "1px solid transparent",
			textFillColor: "#c6c6c6",
			boxShadow: "0 0 0px 1000px var(--chakra-colors-AWPrimary-dark) inset",
			transition: "background-color 5000s ease-in-out 0s",
		},
		px: 0,
	},
});

const searchPageInput = definePartsStyle({
	field: {
		color: "AWPrimary.dark",
		fontFamily: "body",
		fontSize: "1rem",
		fontWeight: "600",
		lineHeight: "1.2",
		letterSpacing: "-0.03",
		borderRadius: "full",
		// border: "1px solid",
		// borderColor: "AWPrimary.dark",
		height: "full",
		_placeholder: {
			color: "AWPrimary.dark",
			opacity: 0.5,
		},
		// px: 4,
		// py: 2,
		_autofill: {
			color: "AWPrimary.dark",
			fontFamily: "body",
			fontSize: "1rem",
			fontWeight: "600",
			lineHeight: "1.2",
			letterSpacing: "-0.03",
			border: "1px solid transparent",
			textFillColor: "#c6c6c6",
			boxShadow: "0 0 0px 1000px var(--chakra-colors-AWFunctional-white) inset",
			transition: "background-color 5000s ease-in-out 0s",
		},
	},
});

const submitFilterList = definePartsStyle({
	field: {
		textAlign: "center",
		cursor: "pointer",
		display: "inline-block",
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: "AWPrimary.midBlue",
		textColor: "AWPrimary.dark",
		fontWeight: 600,
		width: "fit-content",
		height: "fit-content",
		py: 3,
		px: 6,
		borderRadius: "full",
		"&:hover, &:active": {
			backgroundColor: "AWPrimary.darkerBlue",
		},
		_focusVisible: {
			outline: "1px solid",
			outlineCoor: "AWPrimary.dark"
		},
	}
});

const searchFilterList = definePartsStyle({
	field: {
		ps: 3,
		py: 3,
		borderRadius: 0,
		width: "full",
		height: "full",
		textAlign: "start",
		color: "inherit",
		backgroundColor: "transparent",
		borderBottom: "1px solid",
		borderColor: "inherit",
		outline: "0px",
		outlineColor: "none",
		fontFamily: "body",
		fontSize: "1.125rem",
		fontWeight: "600",
		lineHeight: "1.2",
		"&:hover, &:active": {
			backgroundColor: "transparent",
			color: "inherit",
			"& svg": {
				color: "inherit",
				strokeColor: "inherit",
			}
		},
		_focusVisible: {
			outline: "1px solid",
			outlineColor: "inherit"
		},
		_placeholder: {
			color: "inherit",
		},
		_autofill: {
			color: "inherit",
			textColor: "inherit",
			letterSpacing: "-0.03",
			textFillColor: "inherit",
			boxShadow: "0 0 0px 1000px inherit inset",
			transition: "background-color 50000s ease-in-out 0s",
		},
		"&input:-webkit-autofill, &input:-webkit-autofill:hover, &input:-webkit-autofill:focus": {
			fontFamily: "body",
			fontSize: "1.125rem",
			fontWeight: "600",
			lineHeight: "1.2",
			color: "inherit",
			textColor: "inherit",
			textFillColor: "inherit",
		}
	}
});

export const Input = defineMultiStyleConfig({
	baseStyle,
	variants: {
		searchModalDesktop,
		searchModalMobile,
		searchPageInput,
		submitFilterList,
		searchFilterList
	}
});
